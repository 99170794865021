import { getLayerData } from "./utils";
import { getAppTags } from "./app";

export const getPlayStartTag = (options) => {
  const { app } = getAppTags();
  const eventData = [
    {
      eventInfo: {
        eventName: "play video",
        eventAction: "View " + app.name,
      },
    },
  ];

  const { dataLayer } = getLayerData(
    "View Mode",
    eventData,
    options.projectID,
    options.productID
  );

  const dataProps = { ...dataLayer };

  return { ...dataProps };
};

export function GetEnvironment() {
  let environment = "dev";
  const base_url = window.location.origin;

  if (base_url === "https://dgstatic.hallmark.com") {
    environment = "prod";
  } else if (base_url === "https://dgstatic.stage.hallmark.com") {
    environment = "stage";
  } else {
    environment = "dev";
  }

  return environment;
}

export function GetBaseUrl() {
  const env = GetEnvironment();
  switch (env) {
    case "prod":
    default:
      return "https://services.hallmark.com";
      break;

    case "stage":
      return "https://services.stage.hallmark.com";
      break;

    case "dev":
      return "https://services.dev.hallmark.com";
      break;
  }
}

export function GetDataDogInfo() {
  const env = GetEnvironment();
  switch (env) {
    case "prod":
    default:
      return {
        DATADOG_CLIENT_TOKEN: "pube1ca55859bd804fe5a33f636bd01b435",
        DATADOG_ENV: "dotcom-prod",
        DATADOG_APPLICATION_ID: "1fd0d85b-c3ae-4752-b288-d6a37624995e",
        DATADOG_SITE: "datadoghq.com",
      };
      break;

    case "stage":
      return {
        DATADOG_CLIENT_TOKEN: "pube1ca55859bd804fe5a33f636bd01b435",
        DATADOG_ENV: "dotcom-stage",
        DATADOG_APPLICATION_ID: "1fd0d85b-c3ae-4752-b288-d6a37624995e",
        DATADOG_SITE: "datadoghq.com",
      };
      break;

    case "dev":
      return {
        DATADOG_CLIENT_TOKEN: "pube1ca55859bd804fe5a33f636bd01b435",
        DATADOG_ENV: "dotcom-dev",
        DATADOG_APPLICATION_ID: "1fd0d85b-c3ae-4752-b288-d6a37624995e",
        DATADOG_SITE: "datadoghq.com",
      };
      break;
  }
}

import "./StaticUnityViewWithCustomName.scss";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { UnityContent } from "react-unity-webgl";
import Unity from "react-unity-webgl/distribution/components/Unity";
import axios from "axios";
import { LoadingIndicator } from "@hallmark/web.core.feedback.loading-indicator";
import { GetBaseUrl } from "../../../utils/Environment";
import { useAnalyticsContext } from "../../../context/analytics-context";
import { useAnalytics } from "../../../context/analytics-context/analytics-context-hooks";
import { useAppContext } from "../../../context/app-context/app-context";

const StaticUnityViewWithCustomName = (props) => {
  const { inputData, recipientName, cardImages, cardFormat } = props;
  const [unityReady, setUnityReady] = useState(false);
  const { dataLayerAvailable } = useAnalytics();
  const [loadingProgression, setLoadingProgression] = useState(0);

  const defaultViewStyle = {
    height: "60vh",
  };

  const fullViewStyle = {
    height: "75vh",
  };

  const [viewStyle, setViewStyle] = useState(defaultViewStyle);

  const projectDataModel = {
    projectId: "123456",
    templateType: "portrait",
    previewMode: false,
    showCoverPreview: false,
    qualityLevel: 1,
    //2 - 0.5
    //4 - 0.65
    //5 - 0.75
    //6 - 0.85
    cardPanelData: [
      {
        index: 0,
        panelImage:
          "https://content.hallmark.com/webassets/PGM2901/PGM2901_P1_Background.png",
      },
      {
        index: 1,
        panelImage:
          "https://content.hallmark.com/webassets/PGM2901/PGM2901_P2-3_Background.png",
      },
    ],
  };

  const {
    trackAbandon,
    trackAnimationPlayStart,
    trackAnimationPlayCompleted,
    trackAnimationLoadFailure,
    trackPageLoaded,
    trackPinchGesture,
    trackDoubleTapGesture,
    trackError,
    setProductID,
    setProjectID,
    createStatusMessage,
    ACTIONS,
  } = useAnalyticsContext();

  const { showButtons, setShowButtons, setMoveToSection } = useAppContext();

  const UnityComponent =
    (typeof Unity).toString() === "object" ? Unity.default : Unity;

  const unityContent = useMemo(() => {
    return new UnityContent(
      "/static/unity/Build/002.json",
      "/static/unity/Build/UnityLoader.js",
      {
        adjustCanvasToContainer: true,
      }
    );
  }, []);

  useEffect(() => {
    if (inputData !== undefined && inputData.hasOwnProperty("EventName")) {
      unityContent.send(
        "DataReceiver",
        "LoadCustomEventData",
        JSON.stringify(inputData)
      );
    }
  }, [inputData]);

  useEffect(() => {
    unityContent.on("DataReceiverReady", () => {
      if(!unityReady){
        setUnityReady(true);
      }
    });

    if(cardImages && cardImages.length > 0){
      projectDataModel.templateType = cardFormat;
      projectDataModel.cardPanelData[0].panelImage = cardImages[0];
      projectDataModel.cardPanelData[1].panelImage = cardImages[1];
  
      setProjectData(projectDataModel);
    }

  }, []);

  const [isLoaded, setIsLoaded] = useState(false);
  const [projectData, setProjectData] = useState({});
  const [viewed, setProjectViewed] = useState(false);
  const [animationState, setAnimationState] = useState(null);
  const animationTime = useRef(0.0);

  const sendUnityContent = () => {
    unityContent.send(
      "DataReceiver",
      "LoadCustomizedData",
      JSON.stringify(projectData)
    );

    trackPageLoaded();
  };

  window.sendUnityContent = sendUnityContent;

  const GetAnimationTime = () => {
    return animationTime.current;
  };

  useEffect(() => {
    if (unityReady) {
      unityContent.on("loaded", () => {
        // FetchProjectData();
      });
      unityContent.on("debug", (msg) => {
        console.log(msg);
      });

      unityContent.on("progress", (progress) => {
        setLoadingProgression(progress);
      });

      unityContent.on("AnimationConfirmation", () => {
        setProjectViewed(true);
        trackAnimationPlayCompleted();
        setAnimationState(null);
      });

      unityContent.on("DisableButtons", () => {
        setShowButtons(false);
      });

      unityContent.on("EnableButtons", () => {
        setShowButtons(true);
      });

      unityContent.on("MoveToMoreSection", () => {
        setMoveToSection("more");
      });

      unityContent.on("MoveToCoverSection", () => {
        setMoveToSection("coverSilent");
      });

      unityContent.on("EventTrigger", (eventDataString) => {
        const eventData = JSON.parse(eventDataString);
        const { EventName, EventValue } = eventData;
        switch (EventName) {
          case "AnimationState":
            setAnimationState(EventValue);
            break;

          case "AnimationTime":
            const eventFloatVal = parseFloat(EventValue);
            animationTime.current = eventFloatVal;
            break;

          case "ActionDoubleTap":
            const zoomDirection = (EventValue) => {
              if (EventValue === "DirectionIn") {
                return "zoom in";
              } else if (EventValue === "DirectionOut") {
                return "zoom out";
              }
            };
            trackDoubleTapGesture(zoomDirection);
            break;

          case "ActionPinch":
            const pinchDirection = (EventValue) => {
              if (EventValue === "DirectionIn") {
                return "zoom in";
              } else if (EventValue === "DirectionOut") {
                return "zoom out";
              }
            };
            trackPinchGesture(pinchDirection);
            break;

          default:
            break;
        }
      });

      setIsLoaded(true);
      sendUnityContent();
    }
  }, [unityReady]);

  useEffect(() => {
    if (recipientName !== "" && dataLayerAvailable === true) {
      window.addEventListener("beforeunload", (event) => {
        trackAbandon(GetAnimationTime());
      });
    }
  }, [recipientName, dataLayerAvailable]);

  useEffect(() => {
    switch (animationState) {
      case "Start":
        trackAnimationPlayStart();
        break;

      default:
        console.log("Play state reset");
        break;
    }
  }, [animationState]);

  useEffect(() => {
    if (showButtons) {
      setViewStyle(defaultViewStyle);
    } else {
      setViewStyle(fullViewStyle);
    }
  }, [showButtons]);

  return (
    <div className="contentContainer">
      {!isLoaded && (
        <>
          <LoadingIndicator
            title="Loading Digital Greeting"
            description="Your Digital Greeting is Loading"
            role="img"
            addClass=""
          />
        </>
      )}
      <div className="animationContent" style={viewStyle}>
        <UnityComponent
          unityContent={unityContent}
          className={"animationContainer " + (isLoaded ? "show" : "hidden")}
        />
      </div>
    </div>
  );
};

export default StaticUnityViewWithCustomName;

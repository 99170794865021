import { getLayerData } from "./utils";
import { getAppTags } from "./app";

export const getStartFlowTag = (options) => {
  const { app } = getAppTags();
  const startFlowModalEvent = [
    {
      eventInfo: {
        eventName: "flow start",
        eventAction: app.name,
      },
    },
  ];

  const { dataLayer } = getLayerData(
    "View Mode",
    startFlowModalEvent,
    options.projectID,
    options.productID
  );

  const dataProps = { ...dataLayer };

  return { ...dataProps };
};

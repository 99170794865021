import {
  getStartFlowTag,
  getPlayStartTag,
  getPlayCompleteTag,
  getButtonClickDownloadIOSTag,
  getButtonClickMoreTag,
  getButtonClickCoverTag,
  getButtonClickInsideTag,
  getExitFlowTag,
  getAppAbandonTag,
  getGesturePinchTag,
  getGestureDoubleTapTag,
  getAppErrorTag,
} from "./tags";

export const APP_TAGS = {
  START_FLOW: "DG/startFlow",
  PLAY_START: "DG/playStart",
  PLAY_COMPLETE: "DG/playComplete",
  BUTTON_DOWNLOAD_IOS: "DG/downloadButtonIOS",
  BUTTON_MORE: "DG/buttonClickMore",
  BUTTON_COVER: "DG/buttonClickCover",
  BUTTON_INSIDE: "DG/buttonClickInside",
  APP_ABANDON: "DG/appAbandon",
  EXIT_FLOW: "DG/exitFlow",
  PINCH: "DG/pinchGesture",
  DOUBLETAP: "DG/doubletapGesture",
  APP_ERROR: "DG/appError",
};

export const getTag = (tagType, options) => {
  switch (tagType) {
    case APP_TAGS.START_FLOW:
      return getStartFlowTag(options);
    case APP_TAGS.PLAY_START:
      return getPlayStartTag(options);
    case APP_TAGS.PLAY_COMPLETE:
      return getPlayCompleteTag(options);
    case APP_TAGS.BUTTON_DOWNLOAD_IOS:
      return getButtonClickDownloadIOSTag(options);
    case APP_TAGS.BUTTON_MORE:
      return getButtonClickMoreTag(options);
    case APP_TAGS.BUTTON_COVER:
      return getButtonClickCoverTag(options);
    case APP_TAGS.BUTTON_INSIDE:
      return getButtonClickInsideTag(options);
    case APP_TAGS.APP_ABANDON:
      return getAppAbandonTag(options);
    case APP_TAGS.EXIT_FLOW:
      return getExitFlowTag(options);
    case APP_TAGS.PINCH:
      return getGesturePinchTag(options);
    case APP_TAGS.DOUBLETAP:
      return getGestureDoubleTapTag(options);
    case APP_TAGS.APP_ERROR:
      return getAppErrorTag(options);
    default:
      return {};
  }
};

export const createStatusMessage = (err) => {
  return err.response.data.errors.map((error) => {
    return {
      statusMessages: [
        "httpcode",
        err.response.status.toString(),
        "code",
        error.code,
        "description",
        encodeURI(error.description),
      ].join(","),
    };
  });
};

import { useEffect, useState } from "react";
import { DataLayerObject } from "@hallmark/web.page-components.datalayer";
import {
  clearDataLayerEvents,
  clearDataLayerModalEvents,
  clearDataLayerModalLevels,
} from "./analytics-context-helpers";
import { getTag } from "./analytics-context-tags";

export const useAnalytics = () => {
  const [dataLayerAvailable, setDataLayerAvailable] = useState(false);

  useEffect(() => {
    const dataLayerCheckTimer = setInterval(() => {
      if (
        typeof window !== "undefined" &&
        window.dataLayer?.["siteID"] !== "undefined"
      ) {
        setDataLayerAvailable(true);
      }
    }, 500);
    return () => {
      clearInterval(dataLayerCheckTimer);
    };
  }, []);

  const buildTag = (tagType, options, keysToDelete) => {
    const tag = getTag(tagType, options || {});
    clearDataLayerEvents();
    clearDataLayerModalEvents();
    clearDataLayerModalLevels();
    DataLayerObject({
      dataObj: JSON.stringify(tag),
      keysToDelete: keysToDelete || [],
    });
    return tag;
  };

  const track = (trackType = "page") => {
    if (window && window._satellite) {
      window._satellite.track(trackType);
    }
  };

  return {
    dataLayerAvailable,
    buildTag,
    track,
  };
};

import { getLayerData } from "./utils";

export const getButtonClickInsideTag = (options) => {
  const eventData = [
    {
      eventInfo: {
        eventName: "area",
        eventAction: "inside",
      },
    },
  ];

  const { dataLayer } = getLayerData(
    "View Mode",
    eventData,
    options.projectID,
    options.productID
  );

  const dataProps = { ...dataLayer };

  return { ...dataProps };
};

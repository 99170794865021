import { getAppTags } from "./app";

export const getLayerData = (
  level2,
  modalEvent,
  projectID,
  productID,
  messages = null
) => {
  const { app } = getAppTags();
  console.log("App name [" + app.name + "]");
  const data = {
    dataLayer: {
      page: {
        pageInfo: {
          level1: app.name,
          level2,
          pageType: app.name,
          projectID,
        },
      },
      event: modalEvent,
      product: {
        productInfo: [
          {
            productInfoShown: {
              productID,
              productName: "",
              productType: "Digital Greetings",
            },
          },
        ],
      },
    },
  };

  if (messages !== null) {
    data.dataLayer.page.pageInfo = {
      ...data.dataLayer.page.pageInfo,
      messages,
    };
  }

  return data;
};

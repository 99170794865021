import React, { createContext, useContext, useEffect, useState } from "react";

export const AppContext = createContext({});

export const AppContextProvider = ({ children }) => {
  const [showButtons, setShowButtons] = useState(false);
  const [moveToSection, setMoveToSection] = useState("");

  const providerExport = {
    showButtons,
    setShowButtons,
    moveToSection,
    setMoveToSection,
  };

  return (
    <AppContext.Provider value={providerExport}>{children}</AppContext.Provider>
  );
};

// Hooks
export const useAppContext = () => {
  const context = useContext(AppContext);
  if (context === undefined) {
    throw new Error("useAppContext must be used within AppContextProvider");
  }

  return context;
};

import { getLayerData } from "./utils";

export const getAppErrorTag = (options) => {
  const { messages, action } = options;
  const eventData = [
    {
      eventInfo: {
        eventName: "error",
        eventAction: action,
      },
    },
  ];

  const { dataLayer } = getLayerData(
    "View Mode",
    eventData,
    options.projectID,
    options.productID,
    messages
  );

  const dataProps = { ...dataLayer };

  return { ...dataProps };
};

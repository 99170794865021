import { DataLayerObject } from "@hallmark/web.page-components.datalayer";

export const clearDataLayerEvents = () => {
  const event = [];
  DataLayerObject({
    dataObj: JSON.stringify({ event }),
    keysToDelete: ["event"],
  });
};

export const clearDataLayerModalEvents = () => {
  const model = {
    event: [],
  };
  DataLayerObject({
    depth: "modal",
    dataObj: JSON.stringify(model),
    keysToDelete: ["event"],
  });
};

export const clearDataLayerModalLevels = () => {
  const model = {
    level1: "",
    level2: "",
  };
  DataLayerObject({
    depth: "page",
    dataObj: JSON.stringify(model),
    keysToDelete: ["page.level1", "page.level2"],
  });
};

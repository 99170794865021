import "./MoreInfoView.scss";
import { useAnalyticsContext } from "../../context/analytics-context";

const MoreInfoView = () => {
  const { trackIOSAppDownloadButtonClick } = useAnalyticsContext();

  return (
    <div className="more-info-view-container">
      <div className="more-info-view-inner">
        <div className="more-info-item">
          <div>
            <div className="bold">Say thanks!</div>
          </div>
          <div className="description-container">
            <div className="description">Create a card just for them!</div>
            <div className="icon">
              <img src="/static/img/graphic-email.png" alt="Say thanks" />
            </div>
          </div>
        </div>
        <hr />
        <div className="more-info-item">
          <div>
            <div className="bold">Download Hallmark Cards Now</div>
          </div>
          <div className="description-container">
            <div className="description">
              Personalize cards and we’ll mail them for you, or send for free by
              text or email!
            </div>
            <div className="icon">
              <img
                src="/static/img/graphic-app-icon.png"
                alt="Download Hallmark Cards Now"
              />
            </div>
          </div>
        </div>
        <div className="more-info-item">
          <div>
            <button
              onClick={() => {
                trackIOSAppDownloadButtonClick();
                const url =
                  "https://apps.apple.com/us/app/hallmark-cards-now/id1673592071";
                const win = window.open(url, "_blank");
                if (win !== undefined && win !== null) {
                  win.focus();
                }
              }}
            >
              <img
                src="/static/img/button-appstore.svg"
                alt="Download on the App Store"
              />
            </button>
          </div>
        </div>
        <hr />
      </div>
    </div>
  );
};

export default MoreInfoView;
